<template>
  <div class="nav-list">
    <div :class="['nav-btn', (curPath == item.path) && 'cur']" v-for="(item, i) in navList" :key="i" @click="goPage(item.path)">
      {{item.name}}
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      navList: [
        {
          name: '森林防火',
          path: '/forestFirePrevention'
        },
        {
          name: '森林资源监测',
          path: '/investigationMonitor'
        },
        {
          name: '自然保护地',
          path: '/ziranbaohudi'
        },
        {
          name: '古树名木',
          path: '/ancientBrightTrees'
        },
        {
          name: '林长制',
          path: '/linzhangzhi'
        },
      ],
    }
  },
  computed: {
    curPath(){
      return this.$route.path
    }
  },
  methods: {
    goPage(path){
      this.$router.push({
        path
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-list{
  display: flex;
  justify-content: center;
  align-items: center;
  .nav-btn{
    &.cur{
      font-weight: bold;
    }
    width: 170px;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    background: url('./images/nav-btn.png') center center no-repeat;
    background-size: 100% 100%;
    margin-right: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 18px;
    color: #2CFCFA;
    font-style: italic;
    text-align: center;
    &:last-of-type{
      margin-right: 0;
    }
  }
}
</style>