<template>
  <div class="large-screen-container flex flex-col">
    <Header title="河南智慧林业综合平台"></Header>
    <div class="body flex">
      <div class="content">
        <div class="left">
          <div class="left-top">
            <div class="left-top-title">
              河南省自然保护地数量统计（个）
            </div>
            <div class="section">
              <div class="hqxx-section">
                <div class="hqxx-section-label">
                  <i class="hqxx-section-label-icon"></i>
                  <span class="hqxx-section-label-title">自然保护地总数</span>
                </div>
                <div class="hqxx-sction-info">
                  <div class="hqxx-section-info-cell" v-for="(item,i) in drhqsArr" :key="i">
                    {{item != '-' ? item :''}}
                  </div>
                </div>
              </div>
              <div class="hqxx-section park-section">
                <div class="list-top">

                  <div class="label">
                    类型
                  </div>
                  <div style="flex:1;"></div>
                  <div class="num">
                    个数
                  </div>
<!--                  <div class="index">-->
<!--                    面积-->
<!--                  </div>-->
                </div>
                <div class="percent-list">
                  <div class="percent-list-item" v-for="(item,i) in numGroupByType" :key="i">
                    <div class="label">
                      {{item.type2}}
                    </div>
                    <div class="percent-bar">
                      <div class="cur" :style="`width:${item.ratio * 100}%;`"></div>
                    </div>
                    <div class="num">
                      {{item.num}}
                    </div>
<!--                    <div :class="['index','index-'+(i+1)]">-->
<!--                      <span>{{i + 1}}</span>-->
<!--                    </div>-->
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="left-section" v-if="false">
            <div class="left-section-title">
              数据图层
            </div>
            <div class="left-section-content">
              <div class="left-section-item" v-for="(item ,i) in arr1" :key="i">
                <i class="icon rect"></i>
                {{item}}
              </div>
            </div>
          </div>

          <div class="left-section">
            <div class="left-section-title">
              图例
            </div>
            <div class="left-section-content">
              <div :class="['left-section-item', item.code]" v-for="(item ,i) in arr2" :key="i" @click="changeData(item)">
                <i class="icon"></i>
                {{item.name}}
              </div>
            </div>
          </div>
        </div>
        <div class="center">
          <MapChart ref="map" @getCoordinate="getMapClickData" @checkMap="getMapData"></MapChart>
        </div>
        <div class="right">
          <div class="left-medium">
            <div class="left-medium-title">
              {{curTypeName}}面积（公顷)
            </div>
            <div class="left-medium-wrap">
              <div class="percent-list">
                <div class="percent-list-item" v-for="(item,i) in percentArr2" :key="i">
                  <div class="label">
                    {{item.name}}
                  </div>
                  <div class="percent-bar">
                    <div class="cur" :style="`width:${item.percent?item.percent:0}%;`"></div>
                  </div>
                  <div class="num">
                    {{item.num?item.num:0}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom ">
        <Navigation></Navigation>
      </div>
      <el-dialog
              title="自然保护地详情"
              :visible.sync="dialogVisible"
              width="30%">
        <el-descriptions :column="2">
          <el-descriptions-item label="保护地名称">{{detailData.name==null?'暂无':detailData.name}}</el-descriptions-item>
          <el-descriptions-item label="保护地地区">{{detailData.ragion==null?'暂无':detailData.ragion}}</el-descriptions-item>
          <el-descriptions-item label="保护地级别">{{detailData.level==null?'暂无':detailData.level}}</el-descriptions-item>
          <el-descriptions-item label="城市">{{detailData.city==null?'暂无':detailData.city}}</el-descriptions-item>
          <el-descriptions-item label="地区">{{detailData.county==null?'暂无':detailData.county}}</el-descriptions-item>
          <el-descriptions-item label="说明">{{detailData.remark==null?'暂无':detailData.remark}}</el-descriptions-item>
        </el-descriptions>
        <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">关闭</el-button>
  </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import flexs from "@/mixins/flexs.js";
import MapChart from "./components/map-chart";
import Header from '../../components/header'
import Navigation from '../../components/navigation'
import {getPageInfoApi,getDetailList} from '@/api/integratedPlatform'
export default {
  data() {
    return {
      tableData:{},
      curTypeName: '全部',
      percentArr:[{
        name: '森林公园',
        num: 138
      },
      {
        name: '湿地公园',
        num:  108
      },{
        name: '自然保护区',
        num: 68
      },{
        name: '风景名胜区',
        num: 39
      },{
        name: '地质公园',
        num: 33
      }],
      drhqs: '1586',
      arr1: [
        '全部',
        '风景名胜区',
        '自然保护地',
        '森林公园',
        '地质公园',
        '湿地公园',
      ],
      arr2: [{
        name:'全部',
        code:'qb'
      },{
        name: '风景名胜区',
        code: 'fjmsq'
      },{
        name: '自然保护区',
        code: 'zrbhq'
      },{
        name: '森林公园',
        code: 'slgy'
      },{
        name: '地质公园',
        code: 'dzgy'
      },{
        name: '湿地公园',
        code: 'sdgy'
      },],
      cityObj:{
        'fjmsq': {
          datas: {
            'zzs': 1234,
            'pys': 3332,
            'ays': 12342,
            'hbs': 234,
            'xxs': 2234,
            'jzs': 8888,
            'jys': 2657,
            'smxs': 873,
            'lys': 2389,
            'kfs': 987,
            'sqs': 7832,
            'pdss': 6284,
            'xcs': 826,
            'zks': 8324,
            'phs':572,
            'nys': 3845,
            'zmds': 249,
            'xys': 975
          },
          scatter3DArr: [
            {name: '洛阳市', value: [113.26436, 34.342896], num: 200000},
            {name: '周口市', value: [113.649653,33.620357], num: 100},
            {name: '1', value: [114.649653,33.620357], num: 500},
            {name: '2', value: [112.649653,33.620357], num: 500},
            {name: '3', value: [112.649653,32.620357], num: 500},
            {name: '5', value: [114.649653,33.620357], num: 500},
            {name: '6', value: [113.649653,32.620357], num: 4444},
          ]
        },
        'zrbhq': {
          datas: {
            'zzs': 134,
            'pys': 3332,
            'ays': 1242,
            'hbs': 234,
            'xxs': 2234,
            'jzs': 888,
            'jys': 2657,
            'smxs': 873,
            'lys': 239,
            'kfs': 987,
            'sqs': 7832,
            'pdss': 6284,
            'xcs': 826,
            'zks': 8324,
            'phs':572,
            'nys': 3845,
            'zmds': 29,
            'xys': 975
          },
          scatter3DArr: [
            {name: '洛阳市', value: [113.26436, 34.342896], num: 200000},
            {name: '周口市', value: [113.649653,33.620357], num: 100},
            {name: '1', value: [114.649653,33.620357], num: 500},
            {name: '2', value: [112.649653,33.620357], num: 500},
            {name: '3', value: [112.949653,32.620357], num: 500},
            {name: '5', value: [114.649653,33.620357], num: 500},
          ]
        },
        'slgy': {
          datas: {
            'zzs': 1234,
            'pys': 3332,
            'ays': 1232,
            'hbs': 234,
            'xxs': 2234,
            'jzs': 888,
            'jys': 2657,
            'smxs': 873,
            'lys': 2389,
            'kfs': 987,
            'sqs': 7832,
            'pdss': 284,
            'xcs': 826,
            'zks': 8324,
            'phs':572,
            'nys': 3845,
            'zmds': 249,
            'xys': 975
          },
          scatter3DArr: [
            {name: '洛阳市', value: [113.26436, 34.342896], num: 200000},
            {name: '周口市', value: [113.649653,33.620357], num: 100},
            {name: '1', value: [114.699653,33.600357], num: 500},
            {name: '2', value: [112.649653,33.620357], num: 500},
            {name: '3', value: [112.649653,32.620357], num: 500},
            {name: '5', value: [114.649653,33.620357], num: 500},
          ]
        },
        'dzgy': {
          datas: {
            'zzs': 1234,
            'pys': 332,
            'ays': 1342,
            'hbs': 234,
            'xxs': 2234,
            'jzs': 8888,
            'jys': 657,
            'smxs': 873,
            'lys': 2389,
            'kfs': 987,
            'sqs': 7832,
            'pdss': 6284,
            'xcs': 826,
            'zks': 8324,
            'phs':52,
            'nys': 3845,
            'zmds': 249,
            'xys': 975
          },
          scatter3DArr: [
            {name: '洛阳市', value: [113.26436, 34.342896], num: 200000},
            {name: '周口市', value: [113.649653,33.620357], num: 100},
            {name: '2', value: [112.649653,33.620357], num: 500},
            {name: '3', value: [112.649653,32.620357], num: 500},
            {name: '5', value: [114.749653,33.520357], num: 500},
            {name: '6', value: [113.649653,32.620357], num: 4444},
            {name: '7', value: [111.649653,33.920357], num: 333},
          ]
        },
        'sdgy': {
          datas: {
            'zzs': 134,
            'pys': 3332,
            'ays': 1342,
            'hbs': 234,
            'xxs': 2234,
            'jzs': 8888,
            'jys': 2657,
            'smxs': 73,
            'lys': 389,
            'kfs': 987,
            'sqs': 782,
            'pdss': 284,
            'xcs': 86,
            'zks': 8324,
            'phs':572,
            'nys': 3845,
            'zmds': 249,
            'xys': 975
          },
          scatter3DArr: [
            {name: '洛阳市', value: [113.26436, 34.342896], num: 200000},
            {name: '周口市', value: [113.649653,33.620357], num: 40000},
            {name: '1', value: [114.649653,33.620357], num: 500},
            {name: '2', value: [112.649653,33.620357], num: 500},
            {name: '3', value: [112.646653,32.626357], num: 500},
            {name: '6', value: [113.649653,32.620357], num: 4444},
            {name: '7', value: [111.649653,33.920357], num: 333},
          ]
        }
      },
      percentArr2:[
        {
          code: 'zzs',
          name: "郑州市",
          num: 55204,
        },
        {
          code: 'pys',
          name: "濮阳市",
          num: 20057,
        },
        {
          code: 'ays',
          name: "安阳市",
          num: 1547,
        },
        {
          code: 'hbs',
          name: "鹤壁市",
          num: 31686,
        },
        {
          code: 'xxs',
          name: "新乡市",
          num: 6992,
        },
        {
          code: 'jzs',
          name: "焦作市",
          num: 44045,
        },
        {
          code: 'jys',
          name: "济源市",
          num: 40689,
        },
        {
          code: 'smxs',
          name: "三门峡市",
          num: 37659,
        },
        {
          code: 'lys',
          name: "洛阳市",
          num: 45180,
        },
        {
          code: 'kfs',
          name: "开封市",
          num: 21900,
        },
        {
          code: 'sqs',
          name: "商丘市",
          num: 4918,
        },
        {
          code: 'pdss',
          name: "平顶山市",
          num: 3881,
        },
        {
          code: 'xcs',
          name: "许昌市",
          num: 2881,
        },
        {
          code: 'zks',
          name: "周口市",
          num: 9881,
        },
        {
          code: 'phs',
          name: "漯河市",
          num: 1881,
        },
        {
          code: 'nys',
          name: "南阳市",
          num: 881,
        },
        {
          code: 'zmds',
          name: "驻马店市",
          num: 1981,
        },
        {
          code: 'xys',
          name: "信阳市",
          num: 2881,
        },
      ],
      numGroupByType:[],
      mapCountList:[],
      detailList:[],
      detailData:{},
      dialogVisible:false,
      mapData:{},
      percentLists:[]
    }
  },
  components: {
    MapChart,
    Header,
    Navigation
  },
  computed: {
    percentList2(){
      const _arr = [...this.percentArr2]
      _arr.sort((a,b)=>{return b.num - a.num})
      const max = _arr[0].num
      this.percentArr2.forEach(item => {
        item.percent = ((item.num / max)*100).toFixed(2)
        item.numText =  (item.num+'').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      });
      return this.percentArr2
    },
    drhqsArr(){
      return this.convertToArr(this.drhqs)
    },
    percentList(){
      const _arr = [...this.percentArr]
      _arr.sort((a,b)=>{return b.num - a.num})
      const max = _arr[0].num
      this.percentArr.forEach(item => {
        item.percent = ((item.num / max)*100).toFixed(2)
        item.numText =  (item.num+'').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      });
      return this.percentArr
    }
  },
  methods: {
    //地图下钻
    getMapData(e){
      if(e){
        this.mapData=e
        this.getPageInfoApiFun(2)
        this.getDetailListFun()
      }else{
        this.mapData={}
        this.detailList=[]
        this.getPageInfoApiFun(1)
      }
    },
    getMapClickData(e){
      this.detailData=e
      this.dialogVisible=true
    },
    getDetailListFun(){
      let data={
        city:this.mapData.name?this.mapData.name:'',
        type2:this.curTypeName=='全部'?'':this.curTypeName
      }
      getDetailList(data).then(res=>{
        if(res.code==200){
          res.data.forEach(item=>{
            item.value=[item.longitude,item.latitude]
          })
          this.detailList=res.data
          setTimeout(()=>{
            this.$refs['map'].reInit(this.detailList)
          },200)

        }
      })
    },
    getPageInfoApiFun(e){
      let data={
        regionId:this.mapData.name?this.mapData.name:'',
        type:this.curTypeName=='全部'?'':this.curTypeName
      }
      getPageInfoApi(data).then(res=>{
        if(res.code==200){
          if(e==1){
            this.drhqs=res.data.allNum.toString()
            this.numGroupByType=res.data.getNumGroupByType
          }
          this.mapCountList=res.data.getNumGroupByRegion
          this.$refs['map'].setData(this.mapCountList)
          this.percentLists=res.data.getAreaGroupByRegion
          if(res.data.getAreaGroupByRegion.length!=0){
            this.percentArr2.forEach(item=>{
              res.data.getAreaGroupByRegion.forEach(i=>{
                if(item.name==i.city){
                  item.num=i.area
                  item.percent=i.ratio * 100
                }
              })
            })
          }else{
            this.percentArr2.forEach(item=>{
              item.num=0
              item.percent=0
            })
          }

        }
      })
    },
    changeData(item){
      // const {datas, scatter3DArr} = this.cityObj[item.code]
      this.curTypeName = item.name
      this.getPageInfoApiFun(2)
      // this.getDetailListFun()
      // this.percentArr2.forEach(item =>{
      //   item.num = datas[item.code]
      // })
      // this.$refs['map'].reInit(scatter3DArr)
    },
    convertToArr(_str){
      const maxLen = 8
      let _arr = Array.apply(null, Array(10)).map(function() { return '-'; });
      const _beginIndex = maxLen - _str.length
      _arr.splice(_beginIndex)
      _arr = [..._arr, ..._str.split('')]
      return _arr
    },
  },
  mounted(){
    this.getPageInfoApiFun(1)
    // this.getDetailListFun()
  },
  mixins: [flexs],
};
</script>
<style lang="scss">
@import "../../assets/css/index.scss";
</style>
<style lang="scss" scoped>
.large-screen-container {
  height: 100%;
  overflow: hidden;
  background: url('../../assets/images/ls-bg.png') center center no-repeat;
  background-size: 100% 100%;
  padding: 0 35px 39px 59px;
  position: relative;


  .body{
    height: calc(100% - 102px);
    width: calc(100% - 112px);
    position: absolute;
    left: 53px;
    top: 80px;
    display: flex;
    flex-direction: column;
    .content{
      flex: 1;
      display: flex;
      .left{
				text-align: left;
        width: 437px;
        .left-top{
					height: 494px;
          background: url('./images/left-top-bg.png') center center no-repeat;
          background-size: 100% 100%;
          position: relative;
          padding-top: 37px;
          display: flex;
          flex-direction: column;
          .left-top-title{
            left: 55px;
            top: 3px;
						line-height: 1;
            width: 100%;
            text-align: left;
            position: absolute;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 24px;
            color: #D9EAF5;
            text-shadow: 0px 3px 0px rgba(2,13,37,0.3);
            font-style: italic;
            background: linear-gradient(0deg, rgba(100,206,255,0.71) 0%, rgba(250,255,255,0.71) 45.9228515625%, rgba(255,255,255,0.71) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
					.hqxx-section{
						margin-top: 13px;
						margin-left: 35px;
						margin-right: 20px;

						.hqxx-section-label{
							display: flex;
              align-items: center;
							.hqxx-section-label-icon{
								display: block;
								width: 24px;
								height: 24px;
								margin-right: 14px;
								background: url("./images/tree.png") center center no-repeat;
								background-size: 100% 100%;
							}
							.hqxx-section-label-title{
								flex: 1;
								height: 17px;
								line-height: 1;
								font-family: Microsoft YaHei;
								font-weight: 400;
								font-size: 16px;
								color: #FFFFFF;
								text-align: left;
							}
						}
            .hqxx-sction-info{
              margin-top: 10px;
              display: flex;
              justify-content: space-between;
              padding-bottom: 22px;
              border-bottom: 1px solid #1A3066;
              .hqxx-section-info-cell{
                width: 30px;
                height: 43px;
                background: #0D1732;
                border: 1px solid #2D80EC;
                line-height: 43px;
                font-family: PangMenZhengDao;
                font-weight: 400;
                font-size: 30px;
                color: #69FFFA;
                text-align: center;
              }
            }
            &.park-section{
              margin-top: 27px;
              flex: 1;
              flex-direction: column;

              .index{
                width: 33px;
                height: 17px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #D9EAF5;
                font-style: italic;
                text-align: center;
                margin-right: 24px;
                line-height: 17px;
              }

              .label{
                width: 82px;
                height: 17px;
                line-height: 17px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #D9EAF5;
                font-style: italic;
                margin-right: 24px;
                text-align: left;
              }
              .list-top{
                display: flex;
                text-align: center;
                .num{
                  width: 78px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  font-size: 16px;
                  color: #D9EAF5;
                  font-style: italic;
                  line-height: 17px;
                }
                .percent{
                  width: 60px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  font-size: 16px;
                  color: #D9EAF5;
                  font-style: italic;
                  line-height: 17px;
                  text-align: left;
                }
              }
              .percent-list-item{
                margin-top: 40px;
                display: flex;
                align-items: center;
                &:first-of-type{
                  margin-top: 17px;
                }
                .index{
                  >span{
                    display: block;
                    width: 18px;
                    height: 18px;
                    line-height: 18px;
                    margin: 0 auto;
                    background: #152754;
                    border-radius: 2px;
                  }
                  &.index-1{
                    >span{
                      color: #1F2224;
                      background: #E4DE2F;
                    }
                  }
                  &.index-2{
                    >span{
                      color: #1F2224;
                      background: #E4DE2F;
                    }
                  }
                  &.index-3{
                    >span{
                      color: #1F2224;
                      background: #B9B9B9;
                    }
                  }
                }
                .percent-bar{
                  flex: 1;
                  height: 13px;
                  background: #0C1D3F;
                  border-radius: 2px;
                  overflow: hidden;
                  .cur{
                    height: 100%;
                    background-color: #4986D5;
                  }
                }
                .num{
                  width: 78px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  font-size: 16px;
                  color: #D9EAF5;
                  line-height: 17px;
                  text-align: center;
                }
                .percent{
                  width: 60px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  font-size: 16px;
                  color: #D9EAF5;
                  text-align: center;
                  line-height: 17px;
                }
              }
            }
					}
				}
        .left-section{
          height: 156px;
          background: url('./images/left-medium-bg.png') center center no-repeat;
          background-size: 100% 100%;
          padding: 24px;
          margin-top: 40px;
          .left-section-title{
            width: 98px;
            height: 24px;
            line-height: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 24px;
            color: #D9EAF5;
            text-shadow: 0px 3px 0px rgba(2,13,37,0.3);
            font-style: italic;
            background: linear-gradient(0deg, rgba(100,206,255,0.71) 0%, rgba(250,255,255,0.71) 45.9228515625%, rgba(255,255,255,0.71) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .left-section-content{
            display: flex;
            flex-wrap: wrap;
            .left-section-item{
              cursor: pointer;
              margin-top: 26px;
              width: 128px;
              padding-left: 25px;
              height: 16px;
              line-height: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              font-size: 16px;
              color: #D9EAF5;
              font-style: italic;
              position: relative;
              .icon{
                display: block;
                position: absolute;
                width: 12px;
                height: 12px;
                border-radius: 2px;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                background-color: #2E6ED9;
                &.rect::after{
                  content: '';
                  display: block;
                  width: 6px;
                  height: 6px;
                  background: #FFFFFF;
                  border-radius: 2px;
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  margin: auto;

                }
              }
              &.fjmsq{
                .icon{
                  background: #38A800;
                }
              }
              &.zrbhq{
                .icon{
                  background: #AAFFFF;
                }
              }
              &.slgy{
                .icon{
                  background: #3ECDBD;
                }
              }
              &.dzgy{
                .icon{
                  background: #00AAFF;
                }
              }
              &.sdgy{
                .icon{
                  background: #155815;
                }
              }
            }
          }
        }
      }
      .right{
        width: 437px;

        .left-medium{
          margin-top: 8px;
					height: 100%;
          background: url('../linzhangzhi/images/left-top-bg.png') center center no-repeat;
          background-size: 100% 100%;
          position: relative;
          padding-top: 37px;
          display: flex;
          flex-direction: column;
          .left-medium-title{
            left: 55px;
            top: 3px;
						line-height: 1;
            width: 100%;
            text-align: left;
            position: absolute;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 24px;
            color: #D9EAF5;
            text-shadow: 0px 3px 0px rgba(2,13,37,0.3);
            font-style: italic;
            background: linear-gradient(0deg, rgba(100,206,255,0.71) 0%, rgba(250,255,255,0.71) 45.9228515625%, rgba(255,255,255,0.71) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .left-medium-wrap{
            padding: 7px 20px 0 23px;
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            flex-direction: column;

            .list-top{
              display: flex;
              text-align: center;
              .num{
                width: 78px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #D9EAF5;
                font-style: italic;
                line-height: 17px;
              }
              .percent{
                width: 60px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #D9EAF5;
                font-style: italic;
                line-height: 17px;
              }
            }
            .percent-list-item{
              margin-top: 31px;
              display: flex;
              align-items: center;

              &:nth-of-type(2n){
                .percent-bar .cur{
                  height: 100%;
                  background-color: #4986D5;
                }
              }
              &:first-of-type{
                margin-top: 17px;
              }
              .label{
                width: 72px;
                height: 17px;
                line-height: 17px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #D9EAF5;
                font-style: italic;
              }
              .percent-bar{
                flex: 1;
                height: 13px;
                background: #0C1D3F;
                border-radius: 2px;
                overflow: hidden;
                .cur{
                  height: 100%;
                  background-color: #7CDE7C;
                }
              }
              .num{
                width: 78px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #D9EAF5;
                line-height: 17px;
                text-align: right;
              }
              .percent{
                width: 60px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #D9EAF5;
                text-align: center;
                line-height: 17px;
              }
            }
          }
        }
      }
      .center{
        flex: 1;
      }
    }
    .bottom{
      height: 50px;
      margin-top: 13px;
    }
  }
}

</style>
